import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <>
    <div className='wrapper'></div>
    <div className="container">
      <h1>The Standard Guide</h1>
      <p>Thanks to each of you who shared this journey with me. Building for this community was one of the most rewarding experiences in my career. I wish everyone prosperity in their collections and investments and I hope to craft amazing experiences for you again in the future.</p>
      <a href="https://twitter.com/ThStandardGuide">Follow The Standard Guide on twitter for future project updates</a>
    </div>
    </>
  );
}

export default App;
